<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="mb-2">
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Jenis Dokumen MKH</label>
           <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="val => val.value"
            v-model="localData.jenis_dokumen_mkh_id"
            :options="jenisDokumenMkhOptions"
          />
            <small class="mt-1" style="color: red;" v-if="error.jenis_dokumen_mkh_id">{{error.message}}</small>
        </b-col>
        <!-- <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Judul</label>
           <b-form-input
              id="judul"
              autofocus
              trim
              placeholder="Judul Dokumen"
              v-model="localData.judul"
            />
            <small class="mt-1" style="color: red;" v-if="error.nomor">{{error.message}}</small>
        </b-col> -->
         <!-- <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Nomor Dokumen</label>
           <b-form-input
              id="nomor_dokumen"
              autofocus
              trim
              placeholder="Nomor Dokumen"
              v-model="localData.nomor"
            />
            <small class="mt-1" style="color: red;" v-if="error.nomor">{{error.message}}</small>
        </b-col> -->
        <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
          <label>Tanggal Dokumen</label>
           <b-form-datepicker
              id="tgl_dokumen"
              autofocus
              trim
              placeholder="Tanggal Dokumen MKH"
              v-model="localData.tgl_dokumen_mkh"
              :locale="'id'"
            />
            <small class="mt-1" style="color: red;" v-if="error.nomor">{{error.message}}</small>
        </b-col>
        <!-- <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
            <b-button class="btn" variant="outline-primary" :disabled="localData.dokumen === null || localData.template_id === null || localData.nomor === null">Unduh Template</b-button>
        </b-col> -->
      </b-row>
      <b-row>
         <b-col
          cols="12"
          md="6"
          class="mb-2"
        >
            <b-media no-body>
              <b-media-body class="mt-60 ml-60">
                <label>File</label>
                <div class="d-flex" style="width: 100%;">
                  <b-form-file
                    v-if="!$route.params.readonly"
                    v-model="localData.file"
                    placeholder="Masukkan File"
                  >
                  </b-form-file>
                </div>
                <label class="ml-1" style="color: red;" v-if="!$route.params.readonly">Ukuran file max 5mb</label>
              </b-media-body>
            </b-media>
        </b-col>
         <b-col
          cols="12"
          md="6"
          class="mb-2 mt-2"
        >
            <b-button class="btn" variant="primary" @click="tambahData">Simpan</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, BFormInput, BFormFile, BMedia, BMediaBody, BMediaAside, BLink, BCardText, BImg, BFormTextarea, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
    tambahDokumenMKH,
    uploadFileDokumenMKH,
} from '@/connection-api/master'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormInput,
    BFormFile,
    BMedia,
    BMediaBody,
    BMediaAside,
    BLink,
    BCardText,
    BImg,
    BFormTextarea,
    BFormDatepicker,
  },
  data() {
    return {
      error: {},
      localData: {
        logo: require('@/assets/images/logo/logo-dev.png'),
        file: null,
        template_id: null,
        judul: null,
        nomor: null,
        file: null,
      },
    }
  },
  directives: {
    Ripple,
  },
  props: {
    jenisDokumenMkhOptions: {},
  },
  methods: {
    async tambahData()
    {

      if(this.localData.file !== null)
      {
        const responseUpload = await uploadFileDokumenMKH(this.localData.file, event => {
          this.progress = Math.round((100 * event.loaded) / event.total)
        })
        this.localData.nama_file = responseUpload.data.file
      }

      this.localData.laporan_id = this.$route.params.laporan_id
      const response = await tambahDokumenMKH(this.localData)
      if(response.data.success === true)
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Dokumen',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: response.data.message,
            },
          })
          this.$emit('refetchData')
          this.$emit('closeTambahData')
          this.$root.$emit('bv::hide::modal', 'modal_tambah_dokumen_mkh');
      }
      else
      {
        this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Dokumen',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
          this.error = response.data.error
      }
      // console.log(localData)
      // if(this.localData.file !== undefined)
      // {
      //   const responseUpload = await uploadFileDokumen(this.localData.file, event => {
      //     this.progress = Math.round((100 * event.loaded) / event.total)
      //   })
      //   this.localData.nama_file = responseUpload.data.file
      // }

      // this.localData.laporan_id = this.$route.params.laporan_id
      // const response = await tambahDokumen(this.localData)
      // if(response.data.success === true)
      // {
      //   this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: 'Dokumen',
      //         icon: 'CoffeeIcon',
      //         variant: 'success',
      //         text: response.data.message,
      //       },
      //     })
      //     this.$emit('refetchData')
      //     this.$emit('closeTambahData')
      //     this.$root.$emit('bv::hide::modal', 'modal_tambah_dokumen');
      // }
      // else
      // {
      //   this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: 'Dokumen',
      //         icon: 'CoffeeIcon',
      //         variant: 'danger',
      //         text: response.data.message,
      //       },
      //     })
      //     this.error = response.data.error
      // }
    },
    // async tambahData()
    // {

    //   console.log(this.localData)

      // if(this.localData.file !== null)
      // {
      //   const responseUpload = await uploadFileDokumenMkh(this.localData.file, event => {
      //     this.progress = Math.round((100 * event.loaded) / event.total)
      //   })
      //   this.localData.nama_file = responseUpload.data.file
      // }

      // this.localData.laporan_id = this.$route.params.laporan_id
      // const response = await tambahDokumenMkh(this.localData)
      // if(response.data.success === true)
      // {
      //   this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: 'Dokumen',
      //         icon: 'CoffeeIcon',
      //         variant: 'success',
      //         text: response.data.message,
      //       },
      //     })
      //     this.$emit('refetchData')
      //     this.$emit('closeTambahData')
      //     this.$root.$emit('bv::hide::modal', 'modal_tambah_dokumen_mkh');
      // }
      // else
      // {
      //   this.$toast({
      //       component: ToastificationContent,
      //       position: 'top-right',
      //       props: {
      //         title: 'Dokumen',
      //         icon: 'CoffeeIcon',
      //         variant: 'danger',
      //         text: response.data.message,
      //       },
      //     })
      //     this.error = response.data.error
      // }



    // },
     onFileChangeLogo(val) {
      if(val !== null)
      {
        this.localData.logo = URL.createObjectURL(val)
      }
    },
    changeUPT(value) {
      this.$emit('emitReceiverUPT', value)
      this.$emit('update:kanwilFilter', value)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
