<template>
  <b-row>
    <b-col cols="3">
      <status-timeline-card :logData="localData.log_laporans" />
    </b-col>
    <b-col cols="9">
      <form-wizard-number :localData="localData" />
    </b-col>
    
    <b-col cols="12" class="mt-3">
      <b-tabs pills>
  
          <!-- Surat -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dokumen MKH</span>
              </template>
              <dokumen-mkh/>
            </b-tab>
            <!-- <b-tab active>
              <template #title>
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class="mr-50"
                />
                <span class="font-weight-bold">Dokumen</span>
              </template>
              <dokumen/>
            </b-tab> -->
    
      </b-tabs>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BTabs, BTab } from 'bootstrap-vue'
import Pelapor from '@/views/pelapor/list/List.vue'
import KuasaPelapor from '@/views/kuasa-pelapor/list/List.vue'
import Terlapor from '@/views/terlapor/list/List.vue'
import FormWizardNumber from './FormWizardNumber.vue'
import KasusPosisi from '@/views/kasus-posisi/list/List.vue'
import PokokLaporan from '@/views/pokok-laporan/list/List.vue'
import DataPendukung from '@/views/data-pendukung/list/List.vue'
import Analisis from '@/views/analisis/list/List.vue'
import Kesimpulan from '@/views/kesimpulan/list/List.vue'
import SaranAnalisis from '@/views/saran-analisis/list/List.vue'
import TandaTangan from '@/views/tanda-tangan/list/List.vue'
import StatusTimelineCard from '@/views/laporan/view/StatusTimelineCard.vue'
import KelengkapanData from '@/views/kelengkapan-data/list/List.vue'
import Surat from '@/views/surat/list/List.vue'
import Dokumen from '@/views/dokumen/list/List.vue'
import DokumenMkh from '@/views/dokumen-mkh/list/List.vue'
import BeritaAcara from '@/views/berita-acara/list/List.vue'

import {
  getJenisPenyampaian,
  getKantorPenghubung,
  getJenisPerkara,
  getLingkunganPeradilan,
  getProvinsi,
  getKota,
  getJenisPenyampaianById,
  // getJenisDokumen,
  getTplById,
} from '@/connection-api/master'
import {
  generateNomorPenerimaan,
  createDataLaporan,
  updateDataKerjasama,
  getDetailDataLaporan,
} from '@/connection-api/laporan'
export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    FormWizardNumber,
    Pelapor,
    Terlapor,
    KuasaPelapor,
    KelengkapanData,
    PokokLaporan,
    DataPendukung,
    Analisis,
    Kesimpulan,
    SaranAnalisis,
    TandaTangan,
    KasusPosisi,
    StatusTimelineCard,
    KelengkapanData,
    Dokumen,
    Surat,
    BeritaAcara,
    DokumenMkh,
  },
  data() {
    return {
      localData: {
        jenis_penyampaian_id: null,
        jenis_pihak_terlibat_id: null,
        jenis_dokumen_id: null,
        nota_kesepahaman_terkait: null,
      },
      tokenCreate: '',
      jenisPenyampaianOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Penyampaian --' },
        ],
      },
      kantorPenghubungOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kantor Penghubung --' },
        ],
      },
      jenisPerkaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Perkara --' },
        ],
      },
      lingkunganPeradilanOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Lingkungan Peradilan --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi Lingkungan Peradilan --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kab/Kota Lingkungan Peradilan --' },
        ],
      },
      jenisDokumenOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Jenis Dokumen --' },
        ],
      },
    }
  },
  async mounted() {
    const responseDetailLaporan = await getTplById(this.$route.params.laporan_id)
    this.localData = responseDetailLaporan.data.data
  },
  methods: {
     mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
    makeToast(variant = null, message = null) {
      this.$bvToast.toast(message, {
        title: 'Laporan',
        variant,
        solid: true,
        autoHideDelay: 5000,
      })
    },
  },
}

</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
